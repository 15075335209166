.header__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* CTA styling */

.cta__container {
  display: flex;
  flex-direction: row;
  align-self: flex-end;
  gap: 1rem;
  margin: 1rem;
}

/* greeting animation style */

.greeting {
  position: absolute;
  margin-left: 15rem;
  margin-top: 10rem;
}

/* Header socials styling */

.header__socials {
  margin-top: 6rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: flex-start;
  font-size: xx-large;
  max-width: 1.5rem;
  margin-left: 2.5rem;
}

.header__socials::after {
  content: "";
  width: 1px;
  height: 7rem;
  background: var(--color-primary);
  cursor: pointer;
}

/* styling header image of ezra katz logo */

.logo {
  width: 20rem;
  height: 20rem;
  position: absolute;
  right: 8rem;
  bottom: 20rem;
}

/* media queries for medium devices */

@media screen and (max-width: 1024px) {
  header {
    height: 68vh;
  }

  .logo {
    position: absolute;
    left: 23rem;
    top: 0.5rem;
  }
}

/* media queries for small devices */

@media screen and (max-width: 480px) {
  header {
    height: 200vh;
    display: flex;
    flex: column;
    align-items: flex-start;
    justify-content: left;
  }

  .greeting {
    position: absolute;
    top: 1.5rem;
    margin-left: 5rem;
    max-width: 50%;
  }

  .cta__container {
    display: flex;
    flex-direction: row;
    position: absolute;
    top: 21rem;
    right: 7rem;
    font-size: xx-small;
  }

  .header__socials {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: left;
    font-size: medium;
  }

  .logo {
    width: 12rem;
    height: 12rem;
    position: absolute;
    margin-bottom: 4rem;
    margin-left: -14rem;
  }
}
