/* styling the contact container */

.container.contact__container {
  width: 70%;
  display: grid;
  grid-template-columns: 30% 58%;
  gap: 7%;
  position: relative;
  bottom: 2rem;
}

/* styling contact options */

.contact__options {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

.contact__option {
  background: var(--color-bg-variant);
  padding: 1.2rem;
  border-radius: 1.2rem;
  text-align: center;
  border: 1px solid transparent;
  transition: var(--transition);
  position: relative;
  right: 10rem;
  bottom: 8rem;
}

.contact__option h4 {
  font-size: 2rem;
}

.contact__option h5 {
  font-size: 1rem;
}

.contact__option:hover {
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(8px);
  border-color: var(--colorp-primary-variant);
}

.contact__option-icon {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.contact__option a {
  margin-top: 0.7rem;
  display: inline-block;
  font-size: 0.8rem;
}

/* form styling */

form {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

input,
textarea {
  width: 100%;
  padding: 1.5rem;
  border-radius: 0.5rem;
  border: 2px solid var(--color-primary-variant);
  resize: none;
  color: var(--color-white);
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(8px);
}

::placeholder {
  color: var(--color-primary-variant);
}

#contact__btn {
  font-size: xx-large;
}

/* media queries for medium devices */

@media screen and (max-width: 1024px) {
  .container.contact__container {
    grid-template-columns: 1fr;
    gap: 2rem;
    position: relative;
    left: 10rem;
    top: 7rem;
  }
  form {
    position: relative;
    right: 10rem;
    bottom: 4.5rem;
  }
}

/* media queries for small devices */

@media screen and (max-width: 600px) {
  .container.contact__container {
    width: var(--container-width-sm);
  }
}
