@import url("https://fonts.googleapis.com/css2?family=Poppins|Poppins: 100, 200, 300, 400");

* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}

:root {
  --color-bg: #006a6c;
  --color-bg-variant: #008386;
  --color-primary: #ffe463;
  --color-primary-variant: #ffd858;
  --color-white: #fff;
  --color-light: rgba(255, 255, 255, 0.8);

  --transition: all 400 ms ease;

  --container-width-lg: 75%;
  --container-width-md: 86%;
  --container-width-sm: 90%;
}

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  display: none;
}

body {
  font-family: "Poppins", sans-serif;
  background: var(--color-bg);
  color: var(--color-white);
  line-height: 1.7;
  background-image: url(../src/assets/background.png);
  background-repeat: no-repeat;
  background-size: cover;
}

/* General styling */

.container {
  width: var(--container-width-lg);
  margin: 0 auto;
}

h1 {
  font-size: 4rem;
  font-weight: 300;
}

h2 {
  font-size: 3rem;
  font-weight: 200;
}

h3 {
  font-size: 2.8rem;
  font-weight: 200;
}

h4 {
  font-size: 2.5rem;
  font-weight: 150;
}

h5 {
  font-size: 2.3rem;
  font-weight: 100;
}

section {
  margin-top: 8rem;
}

section > h2,
section > h5,
section > h3 {
  text-align: center;
  color: var(--color-light);
}

section > h2 {
  color: var(--color-primary);
  margin-bottom: 3rem;
}

.text-light {
  color: var(--color-light);
}

a {
  color: var(--color-primary);
  transition: var(--transition);
}

a:hover {
  color: var(--color-white);
}

/* button styling */

.btn {
  width: max-content;
  display: inline-block;
  color: var(--color-primary);
  padding: 0.75rem 1.2rem;
  border-radius: 0.4rem;
  cursor: pointer;
  border: 1px solid var(--color-primary);
  transition: 0.3s ease-in-out;
}

.btn:hover {
  background: var(--color-white);
  color: var(--color-bg);
  border-color: transparent;
}

.btn-primary {
  background: var(--color-primary);
  color: var(--color-bg);
  transition: 0.3s ease-in-out;
}

/* image styling */

img {
  display: block;
  width: 100%;
  object-fit: cover;
}

/* media queries for medium devices */

@media screen and (max-width: 1024px) {
  .container {
    width: var(--container-width-md);
  }

  section {
    margin-top: 6rem;
  }
}

/* media queries for small devices */

@media screen and (max-width: 480px) {
  #root {
    overflow-x: hidden;
  }
  .container {
    width: var(--container-width-sm);
  }

  section {
    margin-top: 2rem;
  }
}
